<template>
  <Animation3 />

  <div id="jobs-detail" class="page">
    <div class="job-main">
      <div class="job-title" v-if="job_data?.data">
        <div>
          <!-- <p class="job_date">
            {{ content.JobDateText }}
            {{ format_date(job_data?.data.updated_at) }}
          </p> -->
          <h1 class="BannerHeading">{{ job_data?.data.title }}</h1>

          <p class="job_loc">
            {{ content.LocationText }} :
            <span v-if="job_data?.data.office != null">
              {{ job_data?.data.office.city }},
              {{ job_data?.data.office.country }}
            </span>
            <span v-else>{{ content.EmptyPropertyMsg }} </span>
          </p>
          <a
            href="#careers"
            v-smooth-scroll="{ duration: 1000 }"
            class="btn btn-default exploreBtn"
          >
            {{ content.ApplyBtn }}
            <span class="iconfa">
              <i class="fa fa-chevron-down"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="job-desc" v-if="job_data?.data">
        <h3 class="custom-heading">{{ content.WhatWeOfferText }}</h3>
        <div class="flex">
          <!-- <ul class="customlist">
          <li class="list__item">
           Lorem Ipsum is simply dummy text of the printing and typesetting industry.  
          </li>

          <li class="list__item">
             Lorem Ipsum is simply dummy text of the printing and typesetting industry.

 
          </li>

          <li class="list__item">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.


          </li>
             <li class="list__item">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.


          </li>
             <li class="list__item">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.


          </li>
             <li class="list__item">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.


          </li>
        </ul> -->

          <ul class="customlist" v-html="job_data?.data.what_we_offer"></ul>

          <!-- <div class="summary">
            <div v-html="job_data?.data.short_description" />
          </div> -->
        </div>
        <div>
          <h3>{{ content.ResponsibilitesHeading }}</h3>
          <ul class="customlist" v-html="job_data?.data.responsibilities"></ul>

          <h3>{{ content.IdealCandidateHeading }}</h3>
          <ul class="customlist" v-html="job_data?.data.requirements"></ul>

          <div v-if="perks.length">
            <h3>{{ content.Perks }}</h3>

            <div class="perksGrid">
              <div class="Col" v-for="perk in perks" :key="perk.id">
                <img
                  :src="IMAGES_URL + 'icons/' + perk.img_icon"
                  class="PerksIcon"
                />
                <p>{{ siteLanguage === "GR" ? perk.gr_name : perk.en_name }}</p>
              </div>
            </div>

            <!-- <ul class="customlist">
              <li class="list__item" v-for="perk in perks" :key="perk.id" >
                {{ (clientIp === "PK") ? perk.en_name : perk.gr_name}}   
              </li>
              </ul> -->
          </div>
        </div>
      </div>
    </div>

    <div class="quickCards">
      <div class="container intro-container">
        <a-row>
          <a-col :span="8">
            <div class="cardsMain">
              <div class="v-quick__graphic"></div>
              <div class="cardsCont">
                <h2>{{ content.LifeText }} <Arrow /></h2>
                <p>{{ content.LifeTextp }}</p>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="cardsMain card2">
              <div class="v-quick__graphic"></div>
              <div class="cardsCont">
                <h2>{{ content.ValuesText }} <Arrow /></h2>
                <p>{{ content.ValuesTextp }}</p>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="cardsMain card3">
              <div class="v-quick__graphic"></div>
              <div class="cardsCont">
                <h2>{{ content.PerksText }} <Arrow /></h2>
                <p>{{ content.PerksTextp }}</p>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- <div class="banner">
      <div class="job_date flex justify-center">
        <p>{{ err && err.message }}</p>
      </div> -->

    <!-- <h1>
          {{ job_data?.message !== "jobs found" ? job_data?.message : "" }}
        </h1> -->
    <!-- </div> -->

    <section id="careers" class="details container" v-if="job_data?.data">
      <div>
        <div class="apply">
          <h3>{{ content.CardBoxHeading }}</h3>
          <p>
            {{ content.CardBoxShortDescription }}
          </p>

          <!-- <p>{{ content.CardBoxDescription }}</p> -->
          <p class="sub-heading">{{ applyFormContent.ApplyFormSubHeading }}</p>

          <!-- <a
            href="mailto:careers@gapdynamics.de?subject=Bewerbung als Softwareentwickler (m/w/d) Position"
            class="header-btn"
          >
            <Button name="Jetzt bewerben" />
          </a> -->
          <!-- <router-link
            :to="{ name: 'Apply', params: { slug: job_data.data.slug } }"
          >
            <Button :name="content.CardBoxButtonText" />
          </router-link> -->
          <form
            @submit.prevent="submitForm"
            method="post"
            enctype="multipart/form-data"
            ref="anyName"
          >
            <div class="grid-container">
              <Input
                name="firstname"
                id="firstname"
                v-model="firstname"
                type="text"
                required="true"
                fieldLabel="First Name"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.FirstName
                "
              />

              <Input
                name="lastname"
                id="lastname"
                v-model="lastname"
                type="text"
                required="true"
                fieldLabel="Last Name"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.LastName
                "
              />

              <Input
                name="email"
                id="email"
                v-model="email"
                type="text"
                required="true"
                fieldLabel="Email"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.Email
                "
                :invalidInputMessage="
                  applyFormContent.JobApplyForm.InvalidEmail
                "
              />

              <Input
                name="phone_number"
                id="phone_number"
                v-model="phone_number"
                type="text"
                required="true"
                fieldLabel="Phone Number"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.Phone
                "
                :invalidInputMessage="
                  applyFormContent.JobApplyForm.InvalidPhone
                "
              />

              <Input
                name="linkedUrl"
                id="linkedUrl"
                v-model="linkedUrl"
                type="text"
                required="true"
                fieldLabel="Linked Url"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.LinkedInUrl + ' (e.g. pk.linkedin.com/in/username)'
                " 
                :invalidInputMessage="
                  applyFormContent.JobApplyForm.InvalidWebsiteUrl
                "
              />

              <Input
                name="website"
                id="website"
                v-model="website_url"
                type="text"
                required="false"
                fieldLabel="Portfolio or Website Url"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.WebsiteUrl
                "
                :invalidInputMessage="
                  applyFormContent.JobApplyForm.InvalidWebsiteUrl
                "
              />

              <Input
                name="availability"
                id="availability"
                v-model="availability"
                type="date"
                required="false"
                fieldLabel="availability"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.Availibility
                "
              />

              <Input
                name="heared_about"
                id="heared_about"
                v-model="heared_about"
                type="text"
                required="false"
                fieldLabel="Heard About"
                :placeholder="
                  applyFormContent.JobApplyFormFieldsPlaceHolders.HeardAbout + ' (e.g. Friend/E-mail/Google)'
                "
              />

              <div class="input-wrapper">
                <label style="margin-top: -39px; position: absolute"
                  >Cv/Resume <span style="color: red"> (*)</span></label
                >
                <span class="fieldset-bg"
                  ><span class="fieldset-bg__alert"
                    ><svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="email-error"
                      class=""
                    >
                      <path
                        d="M4 8l1.5 1.5L4 11 2.5 9.5 4 8zm1-7v6H3V1h2z"
                        fill="#FFF"
                      ></path></svg></span
                ></span>

                <input type="file" id="cv" name="cv" ref="file" accept=".pdf" />
              </div>

              <div class="input-wrapper">
                <label style="margin-top: -39px; position: absolute"
                  >Cover Letter (Optional)</label
                >
                <span class="fieldset-bg"
                  ><span class="fieldset-bg__alert"
                    ><svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="email-error"
                      class=""
                    >
                      <path
                        d="M4 8l1.5 1.5L4 11 2.5 9.5 4 8zm1-7v6H3V1h2z"
                        fill="#FFF"
                      ></path></svg></span
                ></span>

                <input
                  type="file"
                  id="cover_letter"
                  name="cover_letter"
                  ref="file"
                  accept=".pdf"
                />
              </div>

              <div class="flex justify-center">
                <Input
                  name="company"
                  id="company"
                  v-model="company"
                  type="text"
                  class="w-full"
                  fieldLabel="Company"
                  required="false"
                  :placeholder="
                    applyFormContent.JobApplyFormFieldsPlaceHolders
                      .CurrentCompany
                  "
                />
              </div>
            </div>
            <br />

            <div class="bottom-section flex justify-end mx-22">
              <span style="">
                <Button
                  class="submit-btn"
                  :name="applyFormContent.FormSubmitButtonText"
                  type="submit"
                  :disable="disable"
                />
              </span>
            </div>
            <div>
              <!-- <h3>Data retrieved from server:</h3> -->
              <!-- <p v-if="success">{{ success }}</p>
          <pre>{{ response }}</pre> -->
            </div>
          </form>
        </div>
        <h3>{{ content.BelowCardHeading }}</h3>
        <router-link :to="{ name: 'Jobs' }">
          <button class="btn btn-alternate underline">
            {{ content.BelowCardLinkText }}
          </button>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import constant from "../config/constants"; //find all the api urls, assets, images path here

import Button from "@/components/Button";
import Input from "@/components/Input";
import Animation3 from "@/components/Animation3";
import moment from "moment";
import { notification } from "ant-design-vue";

// import WebSvg from "@/assets/icons/web.svg";
import axios from "axios";
import { LanguageStrings } from "@/languages";
import Arrow from "@/assets/icons/arrow.svg";

export default {
  name: "JobDetails",

  data: function() {
    return {
      job_data: null,
      perks: null,
      err: "",
      IMAGES_URL: constant.IMAGES_URL,

      job_id: "",
      firstname: "",
      lastname: "",

      email: "",
      phone_number: "",

      company: "",

      website_url: "",
      cv: "",

      linkedUrl: "",
      cover_letter: "",

      availability: "",
      heared_about: "",

      error: "",
      response: "",
      success: "",
      activeClass: "active",
      disable: false,
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },

    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validPhone: function(phone_number) {
      var ph = /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/im;
      return ph.test(phone_number);
    },

    openNotificationWithIcon(type) {
      notification[type]({
        message: `${type}`,
        description:
          type === "error"
            ? this.errors
            : this.applyFormContent.JobApplyForm.SuccessMessage,
      });
      setTimeout(() => {
        this.disable = false;
      }, 6000);
    },
    setValue(event) {
      this.event.target.name = event.target.value;
    },
    submitForm(e) {
      e.preventDefault();
      this.disable = true;

      setTimeout(() => {
        this.disable = false;
      }, 7000);

      this.error = "";

      const formData = new FormData();
      var imagefile = document.querySelector("#cv");
      var imagefile2 = document.querySelector("#cover_letter");
      formData.append("cv", imagefile.files[0]);
      formData.append("cover_letter", imagefile2.files[0]);

      this.cv = imagefile.files[0];
      this.cover_letter = imagefile2.files[0];

      // formData.append("cv", imagefile.files[0]);
      // formData.append("cover_letter", imagefile.files[0]);
      formData.append("first_name", this.firstname);
      formData.append("last_name", this.lastname);
      formData.append("email", this.email);
      formData.append("phone", this.phone_number);
      formData.append("job_id", this.job_data.data.id);
      formData.append("availability", this.availability);

      formData.append("linkedUrl", this.linkedUrl);
      formData.append("website_url", this.website_url);
      formData.append("heared_about", this.heared_about);

      console.log(this.validPhone(this.phone_number), this.phone_number);

      if (
        this.firstname === "" ||
        this.lastname === "" ||
        this.email === "" ||
        this.phone_number === "" ||
        this.linkedUrl === "" ||
        this.cv == "undefined" || 
         this.cv == "" 
      ) {
        this.errors = this.applyFormContent.JobApplyForm.AllFieldsRequired;
        this.openNotificationWithIcon("error");
      } else if (!this.validEmail(this.email)) {
        this.errors = this.applyFormContent.JobApplyForm.InvalidEmail;
        this.openNotificationWithIcon("error");
      } else if (this.validPhone(this.phone_number) == false) {
        this.errors = this.applyFormContent.JobApplyForm.InvalidPhone;
        this.openNotificationWithIcon("error");
      } else {
        axios
          .post(constant.API_URL + "job-apply", formData, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then((response) => {
            if (response.data.status_code === 200) {
              this.message = response.data.message;
              this.openNotificationWithIcon("success");
              (this.firstname = ""),
                (this.lastname = ""),
                (this.phone_number = ""),
                (this.email = ""),
                (this.heared_about = ""),
                (this.linkedUrl = ""),
                (this.website_url = ""),
                (this.cv = ""),
                (this.cover_letter = ""),
                (this.company = ""),
                (this.availability = "");

              this.$refs.anyName.reset();
            } else if (response.data.status_code === 404) {
              this.errors = this.applyFormContent.JobApplyForm.CvError;
              this.openNotificationWithIcon("error");
              this.disable = true;
            } else {
              this.errors = this.applyFormContent.JobApplyForm.ServerError;
              this.openNotificationWithIcon("error");
            }

            this.disable = true;
          })
          .catch((err) => {
            console.log("catach error body", err);
            this.errors = err.response;
            this.errors = this.applyFormContent.JobApplyForm.ServerError;
            this.openNotificationWithIcon("error");
          });
      }
    },
  },

  created() {
    if (localStorage.getItem("siteLanguage") === "GR") {
      this.content = LanguageStrings.gr.JobDetailpage;
      this.applyFormContent = LanguageStrings.gr.JobsApplyPage;
    } else {
      this.content = LanguageStrings.en.JobDetailpage;
      this.applyFormContent = LanguageStrings.en.JobsApplyPage;
    }

    document.querySelector("head title").innerText = this.content.MetaTitle;

    axios
      .get(constant.API_URL + `single-job/${this.$route.params.slug}`)
      .then((response) => {
        this.job_data = response.data;
        this.perks = this.job_data.data.perks;
      })
      .catch((err) => {
        this.err = err;
      });
  },

  components: {
    Button,
    Arrow,
    Animation3,
    Input,
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
